Services.Enums = function Enums() {

    var mapEnumArrayToObject = function (arr, newKey, keyForValue) {
        var ret = {};
        arr.forEach(function (item) {
            if (item[newKey]) {
                if (keyForValue) {
                    ret[item[newKey]] = item[keyForValue];
                } else {
                    ret[item[newKey]] = item;
                }
            }
        });
        return ret;
    };

    var ExtendedCreativeTypes = [
        /* From company types */
        {key: 'event_planner', translationEntry: 'EXTENDED_CREATIVE_TYPES._EVENT_PLANNER_', supported: true},
        {key: 'venue', translationEntry: 'EXTENDED_CREATIVE_TYPES._VENUE_', supported: true},
        {key: 'photographer', translationEntry: 'EXTENDED_CREATIVE_TYPES._PHOTOGRAPHER_', supported: true},
        {key: 'rental', translationEntry: 'EXTENDED_CREATIVE_TYPES._RENTAL_', supported: true},
        {key: 'florist', translationEntry: 'EXTENDED_CREATIVE_TYPES._FLORIST_', supported: true},
        {key: 'stationery', translationEntry: 'EXTENDED_CREATIVE_TYPES._STATIONERY_', supported: true},
        {key: 'other', translationEntry: 'EXTENDED_CREATIVE_TYPES._OTHER_', supported: true},
        {key: 'fashion', translationEntry: 'EXTENDED_CREATIVE_TYPES._FASHION_', supported: true},
        // {key: 'photo_booth', translationEntry: 'EXTENDED_CREATIVE_TYPES._PHOTO_BOOTH_', supported: true},
        {key: 'cinematography', translationEntry: 'EXTENDED_CREATIVE_TYPES._CINEMATOGRAPHY_', supported: true},
        {key: 'event_lighting', translationEntry: 'EXTENDED_CREATIVE_TYPES._EVENT_LIGHTING_', supported: true},
        {key: 'officiant', translationEntry: 'EXTENDED_CREATIVE_TYPES._OFFICIANT_', supported: true},
        {key: 'marketing', translationEntry: 'EXTENDED_CREATIVE_TYPES._MARKETING_', supported: true},
        {key: 'visual_artist', translationEntry: 'EXTENDED_CREATIVE_TYPES._VISUAL_ARTIST_', supported: true},
        {key: 'blogger', translationEntry: 'EXTENDED_CREATIVE_TYPES._BLOGGER_', supported: true},
        {key: 'business_consultant', translationEntry: 'EXTENDED_CREATIVE_TYPES._BUSINESS_CONSULTANT_', supported: true},
        {key: 'calligrapher', translationEntry: 'EXTENDED_CREATIVE_TYPES._CALLIGRAPHER_', supported: true},
        {key: 'interior_designer', translationEntry: 'EXTENDED_CREATIVE_TYPES._INTERIOR_DESIGNER_', supported: true},
        {key: 'graphic_designer', translationEntry: 'EXTENDED_CREATIVE_TYPES._GRAPHIC_DESIGNER_', supported: true},
        {key: 'maker_artisan', translationEntry: 'EXTENDED_CREATIVE_TYPES._MAKER_ARTISAN_', supported: true},
        {key: 'web_designer', translationEntry: 'EXTENDED_CREATIVE_TYPES._WEB_DESIGNER_', supported: true},

        /* Modified company types */
        {key: 'musician', translationEntry: 'EXTENDED_CREATIVE_TYPES._MUSICIAN_', supported: true},
        {key: 'hair_design', translationEntry: 'EXTENDED_CREATIVE_TYPES._HAIR_DESIGN_', supported: true},
        {key: 'makeup_artist', translationEntry: 'EXTENDED_CREATIVE_TYPES._MAKEUP_ARTIST_', supported: true},
        {key: 'entertainment', translationEntry: 'EXTENDED_CREATIVE_TYPES._ENTERTAINMENT_', supported: true},
        {key: 'dj', translationEntry: 'EXTENDED_CREATIVE_TYPES._DJ_', supported: true},
        {key: 'bar_services', translationEntry: 'EXTENDED_CREATIVE_TYPES._BAR_SERVICES_', supported: true},
        {key: 'catering', translationEntry: 'EXTENDED_CREATIVE_TYPES._CATERING_', supported: true},
        {key: 'bakery', translationEntry: 'EXTENDED_CREATIVE_TYPES._BAKERY_', supported: true},
        {key: 'desserts', translationEntry: 'EXTENDED_CREATIVE_TYPES._DESSERTS_', supported: true},

        /* New types */
        {key: 'fireworks', translationEntry: 'EXTENDED_CREATIVE_TYPES._FIREWORKS_', supported: true},
        {key: 'architecture', translationEntry: 'EXTENDED_CREATIVE_TYPES._ARCHITECTURE_', supported: true},
        {key: 'attire_rentals', translationEntry: 'EXTENDED_CREATIVE_TYPES._ATTIRE_RENTALS_', supported: true},
        {key: 'author_writer', translationEntry: 'EXTENDED_CREATIVE_TYPES._AUTHOR_WRITER_', supported: true},
        {key: 'copywriter', translationEntry: 'EXTENDED_CREATIVE_TYPES._COPYWRITER_', supported: true},
        {key: 'crafting', translationEntry: 'EXTENDED_CREATIVE_TYPES._CRAFTING_', supported: true},
        {key: 'ceramics', translationEntry: 'EXTENDED_CREATIVE_TYPES._CERAMICS_', supported: true},
        {key: 'branding', translationEntry: 'EXTENDED_CREATIVE_TYPES._BRANDING_', supported: true},
        {key: 'hand_lettering', translationEntry: 'EXTENDED_CREATIVE_TYPES._HAND_LETTERING_', supported: true},
        {key: 'fiber_art', translationEntry: 'EXTENDED_CREATIVE_TYPES._FIBER_ART_', supported: true},
        {key: 'jewelry', translationEntry: 'EXTENDED_CREATIVE_TYPES._JEWELRY_', supported: true},
        {key: 'merchandiser', translationEntry: 'EXTENDED_CREATIVE_TYPES._MERCHANDISER_', supported: true},
        {key: 'photo_editing', translationEntry: 'EXTENDED_CREATIVE_TYPES._PHOTO_EDITING_', supported: true},
        {key: 'product_designer', translationEntry: 'EXTENDED_CREATIVE_TYPES._PRODUCT_DESIGNER_', supported: true},
        {key: 'publishing', translationEntry: 'EXTENDED_CREATIVE_TYPES._PUBLISHING_', supported: true},
        {key: 'styling', translationEntry: 'EXTENDED_CREATIVE_TYPES._STYLING_', supported: true},
        {key: 'strategy', translationEntry: 'EXTENDED_CREATIVE_TYPES._STRATEGY_', supported: true},
        {key: 'virtual_assistant', translationEntry: 'EXTENDED_CREATIVE_TYPES._VIRTUAL_ASSISTANT_', supported: true},
        {key: 'web_developer', translationEntry: 'EXTENDED_CREATIVE_TYPES._WEB_DEVELOPER_', supported: true},
        {key: 'illustration', translationEntry: 'EXTENDED_CREATIVE_TYPES._ILLUSTRATION_', supported: true},
        {key: 'transportation', translationEntry: 'EXTENDED_CREATIVE_TYPES._TRANSPORTATION_', supported: true},

        /* Unsupported types - non used company types*/
        {key: 'cake', translationEntry: 'EXTENDED_CREATIVE_TYPES._CAKE_', supported: false},
        {key: 'hair_makeup', translationEntry: 'EXTENDED_CREATIVE_TYPES._HAIR_MAKEUP_', supported: false},
        {key: 'dj_musician', translationEntry: 'EXTENDED_CREATIVE_TYPES._DJ_MUSICIAN_', supported: false},
        {key: 'caterer', translationEntry: 'EXTENDED_CREATIVE_TYPES._CATERER_', supported: false},
    ];

    var ExtendedCreativeTypesPlural = { //removing those that dont make sense to the sentence.
        'event_planner': 'Event Planners',
            'venue': 'Venues',
            'photographer': 'Photographers',
            'rental': 'Rental Businesses',
            'florist': 'Florists',
            'fashion': 'Fashion Designers',
            'photo_booth': 'Photo Booths',
            'cinematography': 'Videographers',
            'officiant': 'Officiants',
            'visual_artist': 'Visual Artists',
            'blogger': 'Bloggers',
            'business_consultant': 'Business Consultants',
            'calligrapher': 'Calligraphers',
            'interior_designer': 'Interior Designers',
            'graphic_designer': 'Graphic Designers',
            'maker_artisan': 'Makers',
            'web_designer': 'Web Designers',
            'musician': 'Musicians',
            'hair_design': 'Hair Designers',
            'makeup_artist': 'Makeup Artists',
            'dj': 'DJs',
            'bar_services': 'Bar Services',
            'catering': 'Caterers',
            'bakery': 'Bakers',
            'architecture': 'Architects',
            'author_writer': 'Writers',
            'copywriter': 'Copywriters',
            'jewelry': 'Jewelers',
            'merchandiser': 'Boutique Owners',
            'photo_editing': 'Photo Editors',
            'product_designer': 'Product Designers',
            'publishing': 'Publishers',
            'styling': 'Stylists',
            'strategy': 'Strategists',
            'virtual_assistant': 'Virtual Assistants',
            'web_developer': 'Web Developers',
            'illustration': 'Illustrators',
            'dj_musician': 'DJs',
            'caterer': 'Caterers',
            'other': 'Small Businesses'
    };

    return {
        WorkspaceFileTypes: {
            proposal: 0,
            invoice: 1,
            direct_payment: 2,
            questionnaire: 3,
            brochure: 4,
            timeline: 5,
            agreement: 6
        },

        WorkspaceStatus: {
            lead: 0,
            lead_archived: 1,
            client: 2,
            client_archived: 3,
            lead_sent: 4
        },

        WorkspaceStageCategory: {
            none: "",
            lead: "lead",
            booked: "booked"

        },

        isAllowedBulkEmails: {
            maxProjectsPerBatchExceeded: 'isAllowedBulkEmails_maxProjectsPerBatchExceeded',
            gmailIntegraion: 'isAllowedBulkEmails_gmailIntegraion',
            premiumFeatureOnly: 'isAllowedBulkEmails_premiumFeatureOnly',
            maxDailyPassed: 'isAllowedBulkEmails_maxDailyPassed',
            runningTask: 'isAllowedBulkEmails_runningTask',
            notAllowed: 'isAllowedBulkEmails_notAllowed',
        },

        WorkspaceFileStatus: {
            0: '_NEW_WORKSPACE_CREATE_',
            1: '_WELCOME_EMAIL_SENT_',
            2: '_WELCOME_BROCHURE_SEEN_',
            3: '_QUESTIONNAIRE_SEEN_',
            4: '_QUESTIONNAIRE_ANSWER_',
            5: '_PROPOSAL_CREATED_',
            6: '_PROPOSAL_SENT_',
            7: '_PROPOSAL_BROCHURE_SEEN_',
            8: '_PROPOSAL_SEEN_',
            9: '_AGREEMENT_SEEN_',
            10: '_AGREEMENT_SIGNED_',
            11: '_PAYMENTS_SEEN_',
            12: '_RETAINER_PAID_',
            13: '_PAYMENT_DONE_'
        },

        ContractSigneeTypes: {
            couple_signature: 0,
            planner_signature: 1

        },

        QuestionnaireConnectedEventProperties: {
            eventType: 'event_type',
            eventLocation: 'event_location',
            eventDate: 'event_date',
            eventTime: 'event_time',
            eventGuests: 'event_guests',
            eventBudget: 'event_budget',
            eventDetails: 'event_description',
            event_type: 'Project Type',
            event_location: 'Project Location',
            event_date: 'Project Date',
            event_time: 'Project Time',
            event_guests: 'Project Guests',
            event_budget: 'Project Budget',
            event_details: 'Project Description',
            event_description: 'Project Description'
        },

        QuestionnaireConnectedObjects: {
            event: 'event'
        },

        QuestionnaireQuestionTypes: {
            textbox: 'textbox',
            openQuestion: 'open_question',
            dropdown: 'dropdown',
            radio: 'radio',
            checkbox: 'checkbox'
        },

        ContactFormDataKeys: {
            email: 'email',
            full_name: 'full_name',
            phone_number: 'phone_number',
            event_date: 'event_date',
            event_location: 'event_location',
            event_guests: 'event_guests',
            event_budget: 'event_budget',
            event_details: 'event_details',
            event_services: 'event_services',
            event_type: 'event_type',
            how_client_hear_about: 'how_client_hear_about',
            how_client_hear_about_company: 'how_client_hear_about_company'
        },

        FeedItemTypes: {
            workspace_email: 0,
            workspace_file_email: 1,
            workspace_message: 2,
            activity: 3,
            feed_message: 4,
            file_comment_message: 5,
            generic_message: 6,
            lead: 7,
            preferred_vendors_list: 8,
            reschedule_project_email: 13,
            workspace_flow_email: 14,
            ooo_auto_reply: 16,
            client_enrichment: 20
        },

        WorkflowAutomationTypes: {
            reminder_event_coming_up: 0,
            reminder_unsent_file: 1,
            reminder_file_not_booked: 2,
            inquiry_reply_thank_you: 3,
            automation_send_questionnaire: 4,
            client_payment_reminder: 5,
            client_file_not_viewed: 6,
            reminder_file_sent_email_not_viewed: 7,
            default_file_expiration: 8,
            show_date_conflicts: 9,
            add_automated_emails_to_activity: 10,
            upcoming_payment_reminder: 11,
            outstanding_payment_reminder: 12,
            due_payment_reminder: 13,
            auto_recurring_payment_reminder: 14,
            client_flow_not_viewed: 15,
            send_flow_before_project: 16,
            longer_outstanding_payment_reminder: 17,
            before_late_fee_payment_reminder: 18,
            inquiryReminder: 19
        },

        EmailTypes: {
            proposal: 'proposal',
            invoice: 'invoice',
            payment_reminder: 'payment_reminder',
            request_proposal: 'request_proposal',
            questionnaire: 'questionnaire',
            widget: 'widget',
            brochure: 'brochure',
            inbound: 'inbound',
            timeline: 'timeline',
            general: 'general',
            vendor_referral: 'vendor_referral',
            lead_thank_you: 'lead_thank_you',
            brochure_selection: 'brochure_selection',
            pre_event_questionnaire: 'pre_event_questionnaire',
            client_file_not_viewed: 'client_file_not_viewed',
            lead_email: 'lead_email',
            lead_record_email: 'lead_record_email',
            respond_to_lead: 'respond_to_lead',
            agreement: 'agreement',
            ws_email: 'ws_email',
            add_group_member: 'add_group_member',
            imported_from_gmail: 'imported_from_gmail'
        },

        EmailTypesCd: {
            proposal: 0,
            invoice: 1,
            payment_reminder: 2,
            request_proposal: 3,
            questionnaire: 4,
            widget: 5,
            brochure: 6,
            inboud: 7,
            timeline: 8
        },

        SocialNetworkTypes: {
            facebook: 'facebook',
            instagram: 'instagram',
            twitter: 'twitter',
            linkedin: 'linkedin'
        },

        DraftInvalidation: {
            general: '_VENDOR_VERSION_INVALIDATED_1_',
            client_signed: '_VENDOR_VERSION_INVALIDATED_SIGNED_1_',
            client_paid: '_VENDOR_VERSION_INVALIDATED_PAID_1_',
            payment_reverted: '_VENDOR_VERSION_INVALIDATED_PAYMENT_REVERTED_1_',
            client_updated_agreement: '_VENDOR_VERSION_INVALIDATED_1_'
        },

        COORD_TYPE: {
            'X0': 0,
            'Y0': 1,
            'X1': 2,
            'Y1': 3,
            'W': 4,
            'H': 5
        },

        contactFormErrorValidationTypes: {
            general: 'general',
            redirectUrl: 'redirectUrl',
        },

        contactFormSideBarMenuKeys: {
            settings: 'menu.options.settings',
            contactForms: 'menu.options.contactForms',
			giftCards: 'menu.options.giftCards',
            design: 'menu.options.design',
            fonts: 'menu.options.design.fonts',
            colors: 'menu.options.design.colors',
            options: 'menu.options'
        },

        ABTests: {
            humanOutreach: 'human_outreach', // DONE
            trialerSendMobileAppReminder: 'trialer_send_mobile_app_reminder', // DONE
            extendTrialByType: 'extend_trial_by_type', // DONE
            extendTrialByTypeV2: 'extend_trial_by_type_v2', // DONE
            onboardingInvoice:'onboarding_invoice', // DONE
            onBoardingFourPanels: 'onboarding_4_panels', //DONE
            referralAwarenessSubject: 'referral_awareness_subject', //DONE
            referralAwarenessContent: 'referral_awareness_dashboard', //DONE
            referralAwarenessLandingPage: 'referral_awareness_landingpage', //DONE
            onboardingBanner: 'onboarding_banner', // Done gray
            referralAwarenessFinal: 'referral_awareness_final', //DONE
            paywallTempDiscount: 'paywall_temp_discount', //DONE
            extendTrialV4: 'extend_trial_v4', // DONE
            extendTrialV5: 'extend_trial_v5', // DONE (none)
            intakeFormAtws: 'intake_form_atws', // ['ctrl', 'home'], #Done: home
            communityPretrialerAdV2: 'community_pretrialer_ad_v2', //Done, deleted.
            atwsPretrialerAd: 'atws_pretrialer_ad', //DONE, deleted.
            onboardingSetupGuide: 'onboarding_setup_guide', //DONE true
            trialDuration: 'trial_duration',  //DONE 7
            facebookTrialerDiscount: 'facebook_trialer_discount', // ['20', '50', 'free_months', 'dollar'] DONE
            referralNotifications: 'referral_notifications', // ['off', 'on'] #Done: on
            referringUserPayoutExtraCTA: 'referring_user_payout_extra_cta', // [false, true] #Done: true
            referralModalOneCTA: 'referral_modal_one_cta', //  ['facebook', 'copy'] # Done: 'facebook'
            referralDashboardV3: 'referral_dashboard_versions', // //DONE 'new'
            membership: 'membership', // ['page', 'modal'] // Done 'page'
            referralReminderSubject: 'referral_reminder_subject', // ['waiting', 'expiring'] # Done: both
            homePage: 'home_page', // ['false', 'true']
            homePage50: 'home_page_50', // ['false', 'true']
            inviteeEmailV3: 'invitee_Email_v3', // [['gift', 'avatar'] , DONE gift
            exampleProjectType: 'example_project_type', // ['client_bot', 'first_proposal'] Done: client_bot
            referralAchievements: 'user_referral_achievements', // DONE false
            extendTrialV6: 'extend_trial_v6', // ['plain', 'marketing'], # Done: remove email
            clientAccessibilityTopNav: 'client_accessibility_top_nav', // DONE true
            referralModalFbOrCopyAndFb: 'referral_modal_fb_or_copy_and_fb', // DONE  'copy_and_facebook'
            referralInWsBanner: 'referral_in_ws_banner', //['hide', 'show'] DONE 'hide'
            setupGuideType: 'setup_guide_type', // ['setup_guide_page', 'setup_guide_modal'] #Done: 'setup_guide_modal'
            referralDashboardSuggestions: 'referral_dashboard_suggestions', // [true : false] #Done: true
            onboarding4panels: 'onboarding_4panels', // ['4panels', 'all-in-one', 'what-is-honeybook'] #Done: '4panels'
            atwsCleanUi: 'atws_clean_ui', // ['pipeline', 'clean'] #Done: 'pipeline'
            mwebQuizQuestion : 'mweb_quiz_question', // ['projects', 'revenue'] #DONE: 'revenue'
            onboarding4panelsOrQuiz: 'onboarding_4panels_or_quiz', //['4panels', 'quiz'] #DONE 'quiz'
            accountSetupPrompt: 'account_setup_prompt', //['promptForMaterials', 'promptForSubscription'] # Done: 'promptForSubscription'
            referralAwarenessEmailSender: 'referral_awareness_email_sender', //['steph', 'oz'] DONE Steph
            onboardingQuizQuestions: 'onboarding_quiz_questions', //['2', '4', '6'] DONE: 6
            membershipSetupOffer: 'membership_setup_offer', // ['original', 'setup'], # Done: 'original'
            homeType: 'home_type', // ['home_for_trialers', 'home_for_subscribers'], # Done: 'home_for_trialers'
            exTrialerOfferExtend: 'extrialer_offer_extend', // => ['subscribe', 'extend'] #Done 'subscribe
            editReferralEmail: 'edit_referral_email', // => ['false', 'true'] # Done july 2020 true
            onboardingContactFormTask: 'onboarding_contact_form_task', // => ['true', 'false'], #Done: true
            onboardingSetupGuideStreamlined: 'onboarding_setup_guide_streamlined', // => ['original_dark', 'streamlined_dark'] #Done: 'streamlined_dark'
            onboardingQuizOrNothing: 'onboarding_quiz_or_nothing', // ['quiz', 'nothing'], Done: 'quiz'
            contractsLpsFlow: 'contracts_lps_flow', // ['setup_guide', 'templates'], Done: 'templates'
            accountSetupInSetupGuide: 'account_setup_in_setup_guide',// ['get_help' ,'start_free', 'import_existing'] Done start_free
            canceledMembersReachout: 'canceled_members_reachout',// ['ctrl', 'email']
            onboardingNurtureEmailsYbV2: 'onboarding_nurture_emails_yb_v2', //['value_props', 'branding_first']. DONE, 'branding_first'
            onboardingQuizCriticalQuestions: 'onboarding_quiz_critical_questions', // ['critical', 'all'] # Done: (Moving to pureRomeyQuizCriticalQuestions)
            onboardingSetupGuideHome: 'onboarding_setup_guide_home', // [streamlined_dark, streamlined_home] # Done: 'streamlined_home'
            accountSetupInSetupGuideFree: 'account_setup_in_setup_guide_free', // => ['get_help', 'start_free', 'import_existing'] # Done: 'import_existing'
            contactFormVersion: 'contact_form_version', //['simple_html', 'dynamic_file'] # Done: 'dynamic_file'
            sendFilePreview: 'send_file_preview', // [hide, show]. DONE show
            mwebLandingExperience: 'mweb_landing_experience', // ['setup_guide', 'home'] # Done: 'home'
            streamlinedInvoiceVsProposal: 'streamlined_invoice_vs_proposal', // ['proposal', 'invoice'] #Done: 'invoice'
            mwebBlock: 'mweb_block', // ['unblocked', 'blocked'] #Done
            pureRomeyQuizCriticalQuestions: 'pure_romey_critical_quiz_questions', // ['critical', 'all'] # Done: (Moving to quiz3vs5Quetions)
            sendFilePreviewForMembers: 'send_file_preview_for_members', //['true', 'false'] # Done: true
            accountSetupPromptToAccountSetupPage: 'account_setup_prompt_to_account_setup_page', //['pricing', 'account_setup'], # Done. account_setup
            interactiveOnboarding: 'interactive_onboarding', // ['streamlined_home', 'interactive'] # Done: interactive
            interactiveAddProfileImage: 'interactive_add_profile_image', // ['control', 'switch', 'hide'] # Done: control
            paidDefault: 'paid_default', // ['annual', 'monthly'] Done apr 21
            pushMobileTrialerToDesktop2: 'push_mobile_trialer_to_desktop_email2', // Done. nudge_mobile_web_login_to_desktop
            workflowPlacement: 'workflow_placement', // ['lower', 'upper']. Done: 'lower'
            quiz3vs5Quetions: 'quiz_3_vs_5', // ['3', '5']. Done: '3'
            templatesPageRedesign: 'templates_page_redesign', // ['true', 'false'] # Done: 'true'
            toolsTemplatesNewTrialers: 'tools_templates_new_trialers', // ['template_column', 'original'] # Done: 'original
            giftCardStartWithTemplate: 'gift_card_start_with_template',
            showGetPaidWizard: 'show_get_paid_wizard',  // ['no_pop_up' , 'show_pop_up', #Done 'show_pop_up'
            setupGuideProductKnowledgeLink: 'setup_guide_product_knowledge_link', //['true', 'false'], #Done 'false'
            workflowVsAutomation: 'workflow_vs_automation', //['workflow', 'automation'], #Done 'automation'
            cancellationExtraScreen: 'cancellation_extra_screen', // ['true', 'false'] #Done
            brochureTrainingTemplate: 'brochure_training_template', // ['true', 'false'], #Done 'true'
            cancelPromoTest: 'cancel_promo_test', //[control, five, nine] #Done : nine
            flowsOnboarding: 'flows_onboarding', //[control, flows] #Done : flows
            bookingReview: 'booking_review', //[control, booking_review]
            onboardingFirstStepCTA: 'onboarding_first_step_cta', //[continue, skip, skip_branding, later] #done 'continue'
            onboardingFirstBrandingStepCTA: 'onboarding_first_branding_step_cta', //[continue, skip, skip_branding, later] #done 'later'
            sendTestButton: 'send_test_button',//['control', 'top_nav', 'floating'] Done: 'top_nav'
            mwebVideoInQuiz: 'mweb_video_in_quiz', //['show' => 5,'hide' => 5] Done : 'hide'
            progressBar: 'progress_bar', //['control', 'progress_bar'] Done: 'progress_bar
            t1ActivationAbc: 't1_activation_abc', //['control' ,'legacy_like', 'skip_template'] Done: 'control' + 'legacy_like'
            mwebToDesktopOrApp: 'mweb_to_desktop_or_app', //['push_desktop', 'push_app'] Done: 'push_app'
            galleryPushToPros: 'gallery_push_to_pros', //['control' , 'copy_a', 'copy_b']
            chargeBlockFullnameField: 'charge_block_fullname_field', // ['true', 'false'] #Done
            questionMarkSideBar: 'question_mark_side_bar', //['control', 'new question mark'] #Done 'new_question_mark'
            createNewSmarfFromBlank: 'create_new_smarf_from_blank', //['control', 'new_experience'] #Done 'new_experience'
            smartFilesBuilderOnboarding: 'smart_files_builder_onboarding', // ['control', 'video']  #Done 'control'
            showInvoiceAwareness: 'invoice_awareness_test', //['control', 'invoice_awareness']  #Done : 'invoice_awareness'
            setupGuideCopy: 'setup_guide_copy', //['control', 'new_copy'] #Done : 'new_copy'
            simplifiedCreateTemplate: 'simplified_create_template', //['control', 'simplified_create_template'] #Done: 'control'
            templateGalleryPhotographersVerticalizationAbTest: 'template_gallery_photographers_verticalization_abtest', //['control', 'verticalization'] #Done: verticalization
            pushToGallery: 'push_to_gallery', //['control', 'push_to_gallery'], # Done: push_to_gallery
            redirectDestinationAfterSubscription: 'redirect_destination_after_subscription', // ['control', 'b_redirect_destination']
            accountSetupCopy: 'account_setup_copy', // ['control', 'new_account_setup'] #Done: 'new_account_setup'
            intentInfra: 'intent_infra', //['holdout', 'intent_test']
            invoiceIntentServicesOnboarding: 'invoice_intent_services_onboarding', //['control_legacy_like', 'manual_services'] #Done: manual_services
            invoiceBanner: 'invoice_banner', // ['banner', 'control'] # Done 'banner'
            blockMwebMvp: 'block_mweb_mvp',  //  ['control' => 9, 'appdesktop' => 1], changed to: ['control', 'appdesktop', 'v_edit_file'] #Done: 'v_edit_file'
            intentInSetupGuide: 'intent_in_setup_guide', //['control', 'intent_in_setup_guide'] // Done 'intent_in_setup_guide'
            intentInQuestionMark: 'intent_in_question_mark', //['control', 'intent_in_question_mark'] #Done 'intent_in_question_mark'
            builderTourInvoice: 'builder_tour_invoice', // ['intent_control', 'intent_builder'] #Done 'intent_builder'
            talBucketTest: 'tal_bucket_test', //['tb_control', 'tb_push_to_activation']
            appAaTest: 'app_aa_test', //['a', 'b']
            schedulerIntentOnboarding: 'scheduler_intent_onboarding', //['control', 'scheduler_onboarding'] #Done: 'scheduler_onboarding'
            invoiceTourLastStep: 'invoice_tour_last_step', // ['control', 'share_step'] #Done 'share_step'
            contractIntentOnboarding: 'contract_intent_onboarding', //['control_t1', ' new_contract_onboarding'] #Done 'new_contract_onboarding'
            simplifiedInvoice: 'simplified_invoice', // ['control', 'test']
            homePaymentsPoc: 'home_payments_poc', // ['control_trialer_home', 'variant_easy', 'variant_client', 'variant_business', 'variant_shuffle'] #Done: 'control'
            contractIntentOnboardingV2: 'contract_intent_onboarding_v2', // ['control_original_templates', 'v_contract_templates'] #Done: no winner
            contractIntentOnboardingV3: 'contract_intent_onboarding_v3', // ['control_original_templates', 'v_contract_templates']
            augustPromoDiscount: 'august_promo_discount', //['control' , 'percent', 'months']
            growth2024: 'growth_2024' //['holdout', 'test']
        },

        StatsigExperiments: {
            newHomeTest: 'new_home', // [true,false]
            topNav2023: 'top_nav_2023', //['control_original', 'variant_extended']
            contractTour: 'contract_tour', // ['control', 'variant_contract_tour']
            createNewFileButtonWorkspace: 'workspace_create_new_button', // ['control_new', 'variant_file']
            clientCentricMembers: 'client_centric_members', // ['control_new', 'variant_file']
            clientCentricTrialers: 'client_centric_trialers', // ['control_new', 'variant_file']
            instantBranding: 'brand_import', // ['Control_none', 'v_brand']
            consultantsAdjusments: 'consultants_adjustments',
            companySubTypeSelection: 'company_sub_type_selection',
            leadFormsSetupGuide: 'public_files_setup_guide', // [true, false]
            emptyActivePaymentsCard: 'empty_active_payments_card', // [true, false]
            setupGuideClientflow: 'setup_guide_clientflow', // [true, false]
            invoiceHub: 'invoice_hub',
            filesHub: 'files_tab',
            newOnboardingQuiz: 'onboarding_quiz_2023', // ['control_old_quiz', 'v_intent_quiz', 'v_no_skip']
            workspaceTour: 'workspace_tour',
            servicesHubPage: 'services_hub_page', // [true, false]
            servicesHubPageForConsultants: 'services_hub_page_consultants', // [true, false]
            leadFormsScheduleIntentOnboarding: 'scheduling_intent_onboarding_v2',  // [true, false]
            invoiceAndPaymentHubs: 'invoices_and_payments_hub',
            crmIntentOnboarding: 'crm_onboarding',  // [control_t1, variant_crm]
            leadCaptureIntentOnboarding: 'lead_capture_intent_onboarding_v2',  // [true, false]
            quizRedesignMweb : 'quiz_redesign_mweb', // [control_old, v_redesign]
            recurringInvoiceDiscoverability: 'recurring_invoice_discoverability_experiment', // [true, false]
            newAutomations: 'new_automations_experience', // [true, false]
            globalBannerExp: 'global_banner_exp' // [control, all_pages]
        },
        StatsigFeatureGates: {
            newHomeRollout: 'rollout_new_home_subscribers',
            EmailViewer: 'ng2react_email_viewer',
            clientCentricAllMembers: 'client_centric_all_members',
            organizationViewEnabled: 'organization_view_enabled',
            clientTypeQuizQuestionEnabled: 'client_type_quiz_question_enabled',
            aiComposerWebEnabled: 'ai_composer_web_enabled',
            createNewClientRedirectTrialers: 'create_new_client_redirect_trialers',
            createNewClientRedirectMembers: 'create_new_client_redirect_members',
            SmartFileList: 'ng2react_smart_file_list',
            LegacyFile: 'ng2react_legacy_files',
            ng2reactCancellationFlow: 'ng2react_cancellation_flow',
            oooMode: 'ooo_emails',
            giftCardQuickPay: 'gift_card_quick_pay',
            lateFees: 'invoice_late_fees',
            yearInReview2023: 'year_in_review_2023',
            homeHeaderDelight: 'rollout_home_header_delight',
            tags: 'tags',
            contactFields: 'contact_custom_fields',
            rollout_topnav4all: 'rollout_topnav4all'
        },

        Campaigns: {
            paywall: 'fbpw'
        },

        flowsOnboardingWizardSteps: {
            branding: 'branding_flow',
            services: 'services_flow',
            legacyServices: 'services',
            templates: 'flow_templates',
            selected: 'selected_flow_template'
        },

        PubSubTriggers: {
            companySubscribed: 'companySubscribed',
            startedTrial: 'startedTrial',
            extendedTrial: 'extendedTrial',
            gotPricingPlans: 'gotPricingPlans',
            firstPayableFileSent: 'firstPayableFileSent',
            autoFeedMessage: 'autoFeedMessage',
            openFeedEditor: 'openFeedEditor',
            membershipCanceled: 'membershipCanceled',
            companyChanged: 'companyChanged',
            companyBrandingUpdated: 'companyBrandingUpdated',
            couponApplied: 'couponApplied',
            companyTemplatesUpdated: 'companyTemplatesUpdated',
            otamMigratedToFlows: 'otamMigratedToFlows'
        },

        CompanyOwnerNotificationTypes: {
            0: 'COMPANY_SETTING.TEAM.NOTIFICATIONS._CLIENT_PAYS_',
            1: 'COMPANY_SETTING.TEAM.NOTIFICATIONS._CLIENT_SIGNS_'
        },

        ContactFormBuilderType: {
            questions: 'questions',
            buttons: 'buttons'
        },

        Tools: {
            statuses : {
                new: 'new',
                listed: 'listed',
                active: 'active'
            },

            categories : {
                productivity: 'productivity',
                essentials: 'essentials',
                finance: 'finance',
                leadCapture: 'leadCapture',
                community: 'community',
                templates: 'templates'
            },

            items: {
                publicFiles: 'publicFiles',
                helpCenter: 'helpCenter',
                covid19: 'covid19',
                productUpdates: 'productUpdates',
                referrals: 'referrals',
                creatives: 'creatives',
                community: 'community',
                honeybookCommunity: 'honeybookCommunity',
                giftCards: 'giftCards',
                batchEmails: 'batchEmails',
                chromeExtension: 'chromeExtension',
                contactForm: 'contactForm',
                expenses: 'expenses',
                capital: 'capital',
                payments: 'payments',
                bookkeeping: 'bookkeeping',
                reports: 'reports',
                integrations: 'integrations',
                scheduling: 'scheduling',
                workflows: 'workflows',
                library: 'library',
                templates: 'templates',
                timeTracker: 'timeTracker',
                processMapping: 'processMapping',
                tasks: 'tasks',
                calendar: 'calendar',
                allTemplates: 'allTemplates',
                invoices: 'invoices',
                contracts: 'contracts',
                proposals: 'proposals',
                questionnaires: 'questionnaires',
                flowsTemplates: 'flowTemplates',
                templateGallery: 'templateGallery',
                honeybookPros: 'HoneyBookPros',
                invoicesPage: 'invoicesPage',
                filesHubPage: 'filesHubPage',
                services: 'services',
                financeOverview: 'financeOverview'
            }
        },

        workspaceTabTypes: {
            feed: 'feed',
            files: 'files',
            payments: 'payments'
        },

        AtwsStage: {
            beforeCreatingFirstEvent: 1,
            createdFirstEvent: 2,
            createdSecondEvent: 3
        },

        PaymentMethods: {
            bankAccount: 'bank_account',
            creditCard: 'credit_card'
        },

        PaymentMethodTypes: {
            bankAccount: 'PaymentMethodStripeBankAccount',
            creditCard: 'PaymentMethodStripeCard'
        },

        PaymentAmountTypes: {
            customAmount: 'fixedAmount',
            percentage: 'percentageAmount',
            divideEqually: 'divideEquallyAmount'
        },

        IntakeFormSource: {
            tools: 'tools',
            onboardingPanel: 'onboardingPanel',
            pipeline: 'pipeline',
            topNav: 'topNav',
            urlParam: 'urlParam',
            communityOpsAd: 'communityOpsAd',
            workspaceAd: 'workspaceAd',
            settings: 'settings'
        },

        IntakeFormVersion: {
            communityTools: 'communityTools', //NA
            communityTrial: 'communityTrial',
            communityOriginal: 'communityOriginal', //NA
            workflowOriginal: 'workflowOriginal', //NA
            workflowHome: 'workflowHome'
        },

        LandingPages: {
          smartBooking: 'smart-booking'
        },

        ExpenseCategories: {
            0: {label: 'Expenses',
                options: {
                    0: {id: 1, symbol:'accounting_fees', name: 'Accounting Fees'},
                    1: {id: 2,symbol:'advertising_marketing', name: 'Advertising/Marketing'},
                    2: {id: 3,symbol:'auto_insurance', name: 'Auto insurance'},
                    3: {id: 4,symbol:'auto_repairs', name: 'Auto repairs'},
                    4: {id: 5,symbol:'automobile_expense', name: 'Automobile expense'},
                    5: {id: 6,symbol:'bank_service_charges', name: 'Bank service charges'},
                    6: {id: 7,symbol:'casual_help', name: 'Casual help'},
                    7: {id: 8,symbol:'internet_expenses', name: 'Computer & internet expenses'},
                    8: {id: 9,symbol:'contractors', name: 'Contractors'},
                    9: {id: 10, symbol:'credit_card_interest',name: 'Credit card interest'},
                    10: {id: 11,symbol:'dues_subscriptions', name: 'Dues & subscriptions'},
                    11: {id: 12,symbol:'equipment_rental', name: 'Equipment rental'},
                    12: {id: 13,symbol:'gas', name: 'Gas & fuel'},
                    13: {id: 14,symbol:'graphic_design', name: 'Graphic design'},
                    14: {id: 15,symbol:'office_expenses', name: 'Home office expenses'},
                    15: {id: 16,symbol:'insurance', name: 'Insurance'},
                    16: {id: 17,symbol:'legal_fees', name: 'Legal fees'},
                    17: {id: 18,symbol:'licenses', name: 'Licenses & permits'},
                    18: {id: 19,symbol:'loan_interest', name: 'Loan interest'},
                    19: {id: 20,symbol:'entertainment', name: 'Meals & entertainment'},
                    20: {id: 21,symbol:'office_supplies', name: 'Office supplies'},
                    21: {id: 22,symbol:'other', name: 'Other'},
                    22: {id: 23,symbol:'parking', name: 'Parking & tolls'},
                    23: {id: 24,symbol:'delivery', name: 'Postage & delivery'},
                    24: {id: 25,symbol:'printing', name: 'Printing expenses'},
                    25: {id: 26,symbol:'professional_fees', name: 'Professional fees'},
                    26: {id: 27,symbol:'rent', name: 'Rent'},
                    27: {id: 28,symbol:'maintenance', name: 'Repairs & Maintenance'},
                    28: {id: 29,symbol:'salaries', name: 'Salaries & wages'},
                    29: {id: 30,symbol:'session_costs', name: 'Session costs'},
                    30: {id: 31,symbol:'small_equipment', name: 'Small equipment'},
                    31: {id: 32,symbol:'software', name: 'Software'},
                    32: {id: 33,symbol:'taxes', name: 'Taxes'},
                    33: {id: 34,symbol:'travel', name: 'Travel expenses'},
                    34: {id: 35,symbol:'utilities', name: 'Utilities'},
                    35: {id: 36,symbol:'website', name: 'Website expenses'}
                }
            },
            1: {label: 'Costs of Goods Sold',
                options: {
                    0: {
                        id: 37,
                        symbol:'costs_of_goods_sold',
                        name: 'Costs of Goods Sold'},
                    1: {
                        id: 38,
                        symbol:'materials_supplies',
                        name: 'Materials & Supplies'},
                    2: {
                        id: 39,
                        symbol:'merchant_fees',
                        name: 'Merchant Fees'},
                    3: {
                        id: 40,
                        symbol:'sales_commissions',
                        name: 'Sales Commissions'}
                }
            }
        },

        CanadaInstitutionNumbers: {
            '830': "Airline Financial Credit Union Limited",
            '219': "Alberta Treasury Branches",
            '810': "All Trans Financial Services Credit Union Limited",
            '890': "Alliance des caisses populaires de l'Ontario Limitée",
            '842': "Alterna Savings and Credit Union",
            '303': "Amex Bank of Canada",
            '808': "Arnstein Community Credit Union Limited",
            '839': "Atlantic Central",
            '618': "B2B Bank (formerly B2B Trust)",
            '241': "Bank of America National Association",
            '177': "Bank of Canada",
            '308': "Bank of China (Canada)",
            '001': "Bank of Montreal",
            '245': "Bank of Tokyo-Mitsubishi UFJ (Canada)",
            '342': "Bank West",
            '360': "Barclay's Bank PLC Canada Branch",
            '250': "BNP Paribas (Canada)",
            '311': "BofA Canada Bank",
            '347': "Bridgewater Bank",
            '815': "La Caisse Centrale Desjardins Du Québec",
            '010': "Canadian Imperial Bank of Commerce",
            '338': "Canadian Tire Bank",
            '030': "Canadian Western Bank",
            '323': "Capital One Bank (Canada Branch)",
            '809': "Central 1 Credit Union British Columbia",
            '828': "Central 1 Credit Union Ontario",
            '548': "CIBC Trust Corporation",
            '260': "Citibank Canada",
            '328': "Citibank N.A.",
            '309': "Citizens Bank of Canada",
            '330': "Comerica Bank",
            '807': "Communication Technologies Credit Union Limited",
            '507': "Community Trust Company",
            '853': "Concentra Financial Services Association",
            '899': "Credit Union Central Alberta Limited",
            '879': "Credit Union Central of Manitoba Limited",
            '889': "Credit Union Central of Saskatchewan",
            '608': "CS Alterna Bank",
            '315': "CTC Bank of Canada",
            '265': "Deutsche Bank AG",
            '352': "DirectCash Bank",
            '840': "Dundalk District Credit Union Limited",
            '343': "Dundee Bank of Canada",
            '532': "Effort Trust Company",
            '345': "Fifth Third Bank",
            '332': "First Commercial Bank",
            '310': "First Nations Bank of Canada",
            '865': "La Fédération des caisses populaires Acadiennes Limitée",
            '829': "La Fédération des caisses populaires de l'Ontario Inc.",
            '819': "La Fédération des caisses populaires du Manitoba Inc.",
            '344': "General Bank of Canada",
            '844': "Goderich Community Credit Union Limited",
            '854': "Golden Horseshoe Credit Union Limited",
            '321': "Habib Canadian Bank",
            '358': "HomEquity Bank",
            '630': "Household Trust Company",
            '016': "HSBC Bank Canada",
            '333': "HSBC Bank USA National Association",
            '604': "HSBC Mortgage Corporation (Canada)",
            '340': "ICICI Bank Canada",
            '625': "Industrial Alliance Trust Inc.",
            '307': "Industrial and Commercial Bank of China (Canada)",
            '614': "ING Bank of Canada",
            '536': "Investors Group Trust Co. Ltd.",
            '314': "J.P. Morgan Bank Canada",
            '354': "Jameson Bank",
            '270': "JPMorgan Chase Bank National Association",
            '275': "Korea Exchange Bank of Canada",
            '803': "Latvian Credit Union Limited",
            '039': "Laurentian Bank of Canada",
            '522': "Laurentian Trust of Canada Inc.",
            '357': "M&T Bank",
            '540': "Manulife Bank of Canada",
            '626': "Manulife Trust Company",
            '336': "Maple Bank",
            '269': "Mega International Commercial Bank (Canada)",
            '837': "Meridian Credit Union",
            '277': "Mizuho Corporate Bank Ltd. Canada Branch",
            '361': "MonCana Bank of Canada",
            '550': "Montreal Trust Company of Canada",
            '006': "National Bank of Canada",
            '590': "National Trust Company",
            '846': "Ontario Civil Service Credit Union Limited",
            '334': "Pacific & Western Bank of Canada",
            '568': "Peace Hills Trust Company",
            '621': "Peoples Trust Company",
            '322': "Rabobank Nederland",
            '620': "ResMor Trust Company",
            '592': "Royal Bank Mortgage Corporation",
            '003': "Royal Bank of Canada",
            '240': "Royal Bank of Scotland N.V. (Canada) Branch",
            '570': "Royal Trust Company",
            '580': "Royal Trust Corporation of Canada",
            '606': "Scotia Mortgage Corporation",
            '355': "Shinhan Bank Canada",
            '292': "Société Générale (Canada Branch)",
            '346': "Société Générale (Canada Branch) Ontario",
            '294': "State Bank of India (Canada) Alberta",
            '327': "State Street",
            '301': "Sumitomo Mitsui Banking Corporation of Canada",
            '551': "Sun Life Financial Trust Inc.",
            '597': "TD Mortgage Corporation",
            '603': "TD Pacific Mortgage Corporation",
            '242': "The Bank of New York Mellon",
            '002': "The Bank of Nova Scotia",
            '509': "The Canada Trust Company",
            '623': "The Equitable Trust Company",
            '349': "The Northern Trust Company Canada Branch",
            '004': "The Toronto-Dominion Bank",
            '318': "U.S. Bank National Association",
            '339': "UBS AG Canada Branch",
            '290': "UBS Bank (Canada)",
            '335': "United Overseas Bank Limited",
            '359': "Walmart Canada Bank"
        },

        OpportunityTypes: {
            paid_opportunity: 'paid opportunity',
            collaboration: 'collaboration',
            offer_services: 'services offered',
            other: 'opportunity'
        },

        UserStatus: {
            activated: 'ACTIVATED',
            trial: 'TRIAL',
            preTrialer: 'PRE-TRIALER',
            trialExpired: 'EXPIRED_TRIAL',
            subscribed: 'SUBSCRIBED',
            honeypaid: 'HONEYPAID',
            subCancel: 'SUB_CANCEL',
            activatedSubChurn: 'ACTIVATED_SUB_CHURN'
        },

        AutoPaymentStatus: {
            inactive: 'inactive',
            pendingApproval: 'pending_approval',
            active: 'active'
        },

        ExtendedCreativeTypes: ExtendedCreativeTypes,
        SupportedCreativeTypes: ExtendedCreativeTypes.filter(function (t) {return t.supported;}),
        ExtendedCreativeTypesTranslationMap: mapEnumArrayToObject(ExtendedCreativeTypes, 'key', 'translationEntry'),

        ExtendedCreativeTypesPlural: ExtendedCreativeTypesPlural,

        HBFraudBusinessType: {
            services: 'Services',
            commodity: 'Commodity',
            inventory: 'Inventory',
            reset: '-'
        },

        HBFraudType: {
            account_takeover: 'Account takeover',
            client_fraud: 'Client fraud',
            high_risk: 'High risk',
            email_takeover: 'Email takeover',
            fraudulent_account: 'Fraudulent account',
            gift_card_fraud: 'Gift card fraud',
            predator_account: 'Predator account',
            pro_service_abuse: 'Pro service abuse',
            referral_fraud: 'Referral fraud',
            rogue_vendor: 'Rogue vendor',
            rogue_vendor_employee:'Rogue vendor employee',
            false_positive:'False positive',
            system_abuse: 'System abuse',
            unknown:'Unknown',
            reset: '-'
        },

        HBFraudVector: {
            account_takeover: {
                bank_account_change: 'Bank account change',
                two_factor_auth_change: '2FA change',
                unknown:'Unknown'
            },
            high_risk: {
                stripe_restricted: 'Stripe restricted',
                not_stripe_restricted: 'Not Stripe restricted',
                unknown:'Unknown'
            },
            client_fraud: {
                credit_card: 'Credit card',
                ach: 'ACH',
                social_engineered_customer: 'Social engineered customer',
                third_party: '3rd party',
                unknown:'Unknown'
            },
            email_takeover: {
                two_factor_auth_change: '2FA change',
                adding_debit_card: 'Adding debit card',
                unknown:'Unknown'
            },
            fraudulent_account: {
                takenover_account: 'Takenover account',
                social_engineered_account: 'Social engineered account',
                own_account: 'Own account',
                unknown:'Unknown'
            },
            gift_card_fraud: {
                predator_account: 'Predator account',
                client_account: 'Client account',
                unknown:'Unknown'
            },
            predator_account: {
                takenover_account: 'Takenover account',
                accomplice_account: 'Accomplice account',
                own_account: 'Own account',
                unknown:'Unknown'
            },
            pro_service_abuse: {
                bank_account_change: 'Bank account change',
                two_factor_auth_change: '2FA change',
                unknown:'Unknown'
            },
            referral_fraud: {
                referral_fraud: 'Referral fraud',
                unknown:'Unknown'
            },
            rogue_vendor: {
                client_credit_card: 'Client credit card',
                own_credit_card: 'Own credit card',
                unknown:'Unknown'
            },
            rogue_vendor_employee: {
                client_credit_card: 'Client credit card',
                owner_account_takeover: 'Owner account takeover',
                unknown:'Unknown'
            },
            false_positive: {
                false_positive:'False positive',
                unknown:'Unknown'
            },
            system_abuse: {
                unknown:'Unknown'
            },
            unknown: {
                unknown:'Unknown'
            },
            reset: {
                reset: '-'
            }
        },

        HBDisputeStatus: {
            collecting_information: 'In Contact - Collecting Information',
            ready_to_submit:        'Ready to Submit ',
            forter_insured:         'Forter Insured',
            submitted:              'Submitted',
            won:                    'Won',
            lost_bad_vendor:        'Lost - Bad Vendor',
            lost_for_good:          'Lost - For Good',
            lost_lost:              'Lost - Lost',
            lost_accepted:          'Lost - Accepted',
            lost_to_recover:        'Lost - To Recover',
            lost_recovered:         'Lost - Recovered',
            lost_fraud:             'Lost - Fraud',
            lost_hb_subscription:   'Lost - HB Subscription',
            lost_hb_refund:         'Lost - HB Refund'
        },

        FraudAgents: {
            'all': 'All',
            'unassigned': 'Unassigned',
            '660f02b3131242001f3d3a6d': 'Joey Tran',
            '66188cf92077b80019657f0d': 'Karen Rock',
            '6629169b2bef1f000e8d382e': 'John Perfumo',
            '660f017126beb6001925f437': 'Miguel Perez',
            '66180bf89f97d80019e41e33': 'Scott Phillips',
        },

        DisputeAgents: {
            'all': 'All',
            'unassigned': 'Unassigned',
            '5fd2a5b4142d0008fbc00b12': 'Renee Van Andel',
            '60d64cfe29bd0214f262a7c5': 'Scott Phillips',
            '554935441490061e33000001': 'John Perfumo',
            '6564ab9f28ab260028ea0caf': 'Miguel Perez',
            '669560306629e7001ce36f8d': 'Meghan Chang',
        },

        FraudResearchStages: {
            'all': 'All',
            'initial': 'Initial',
            'in_progress': 'In progress',
            'funds_pending': 'Pending funds',
            'pending_documents': 'Pending documents',
            'documents_uploaded': 'Documents uploaded',
            'call_needed': 'Call needed',
            'archived': 'Archived'
        },

        DisputeExternalMitigationStatus: {
            '': 'All',
            not_submitted:  'Not submitted',
            submitted: 'Submitted',
            not_fight: 'Skip',
            skip_automation: 'Manual'
        },

        HBRecollectionStatus: {
            invoice_sent: 'Invoice sent',
            invoice_paid: 'Invoice paid',
            tier3_invoice_sent: 'Invoice sent (Tier 3)',
            tier3_invoice_paid: 'Invoice paid (Tier 3)',
            payouts_disabled: 'Payouts disabled (payout verification needed)',
            auto_reverse_from_bank: 'Bank debit pending',
            auto_reverse_from_bank_failed: 'Bank debit failed',
            auto_reverse_from_bank_success: 'Bank debit successful',
            pending_future_payments: 'Pending future payments',
            debit_failed_pending_future_payments: 'Debit failed. Pending future payments',
            partial_reserve_future_payments: 'Future funds partially recovered',
            full_reserve_future_payments: 'Future funds fully recovered',
            reserved_and_pushed_back: 'Vendor Won (reserved and pushed back)',
            stop_recollect_on_win: 'Vendor Won',
            manual_stop_recollect: 'Recollection was manually cancelled',
            manual_full_recollect: 'Marked as manually recollected',
            dispute_inquiry_no_recollect: 'Inquiry (Recollection Delayed)'
        },

        CreateOpportunityOptionalSources: {
            main: 'main',
            secondary: 'secondary',
            empty_state: 'empty_state',
            popup_banner_after_user_interaction: 'popup_banner_after_user_interaction',
            edit: 'edit'
        },

        DisputeStatuses: {
            '': 'All',
            lost: "Lost",
            won: "Won",
            warning_closed: "Warning Closed",
            under_review: "Under Review",
            needs_response: "Needs Response",
            warning_needs_response: "Warning Needs Response",
            warning_under_review: "Warning Needs Response",
            partially_won: "Partially Won",
            chargeback: "Chargeback",
            chargeback_reversed: "Chargeback Reversed",
            notification_of_chargeback: "Notification of Chargeback",
            notification_of_fraud: "Notification of Fraud",
            prearbitration_lost: "Prearbitration Lost",
            prearbitration_won: "Prearbitration Won",
            request_for_information: "Request for Information",
            second_chargeback: "Second Chargeback"
        },

        FraudStatuses: {
            verified: 'Verified',
            needs_verification: 'Needs Verification',
            suspicious: 'Suspicious',
            certain_fraud: 'Certain Fraud',
            escalation: 'Escalation',
            watched: 'Watched'
        },

        PayoutStatuses: {
            payouts_enabled: 'Enabled',
            payouts_disabled: 'Disabled',
            dispute_payouts_disabled: 'Disabled by dispute',
            lien_payouts_disabled: 'Disabled (Lien)'
        },

        pricingPlanTypes: {
            lifetime: 'lifetime',
            monthly: 'monthly',
            annual: 'annual',
            free: 'free',
            manual: 'manual',
            monthlyBasic: 'monthly_basic',
            starterMonthly: 'starter_monthly',
            unlimitedAnnual: 'unlimited_annual',
            unlimitedMonthly: 'unlimited_monthly'
        },

        timeTrackerSource: {
            tool: 'tool',
            all_entries_modal: 'all_entries_modal',
            workspace: 'workspace'
        },
        timeTrackerFilters: {
            allTime: 'all_time',
            last30Days: 'last_30_days',
            last90Days: 'last_90_days',
            customRange: 'custom_range',
        },

        taxTypes: {
            'relative': 'relative',
            'absolute': 'absolute'
        },

        discountTypes: {
            'relative': 'relative',
            'absolute': 'absolute'
        },

        createFileOriginType: {
            blank: 'blank',
            file: 'file',
            template: 'template'
        },

        badgePillTypes: {
            lead_sources: 'lead_source',
            company_spaces: 'company_spaces'
        },

        homeRecentActivityTypes: {
            contract_signed: 'contract_signed',
            feed_message: 'feed_message',
            flow_contract_signed: 'flow_contract_signed',
            flow_payment_paid: 'flow_payment_paid',
            payment_paid: 'payment_paid',
            flow_services_selected: 'flow_services_selected',
            smart_file_created: 'smart_file_created',
            legacy_file_created: 'legacy_file_created',
            smart_file_sent: 'smart_file_sent',
            legacy_file_sent: 'legacy_file_sent',
            smart_file_completed: 'smart_file_completed',
            legacy_file_completed: 'legacy_file_completed',
            smart_file_viewed: 'smart_file_viewed',
            legacy_file_viewed: 'legacy_file_viewed',
            session_scheduled: 'session_scheduled',
            session_rescheduled: 'session_rescheduled',
            session_canceled: 'session_canceled'
        },

        createNew: {
            invoice : "invoice",
            project: "project",
            client: "client",
            meeting: "meeting",
            flow: "flow",
            contract: "contract",
            lead_form: "leadForm",
            service: "service"
        },

        fileBuilderBlockTypes: {
            content: 'content',
            questions: 'questions',
            proposal: 'proposal'
        },

        fileBuilderContentTypes: {
            text: 'text',// 'full_width_text',
            image: 'image',// 'cover_image',
            two_column_text: 'two_column_text',// 'two_column_text',
            two_column_images: 'two_column_images',// 'two_images',
            text_over_image: 'text_over_image',// 'text_on_image',
            text_and_image: 'text_and_image',// 'left_aligned'
            component: 'component',
        },

        questionsCategories: {
            suggested: 'suggested',
            custom: 'custom',
        },

        customQuestions: {
            short_text: 'open_question',
            long_text: 'textarea',
            drop_down: 'dropdown',
            two_fields: 'two_fields',
            single_choice: 'radio',
            multiple_choice: 'checkbox',
            date_picker: 'date_picker',
            file_upload: 'file_upload',
            picture_choice: 'picture_choice'
        },

        suggestedQuestions: {
            full_name: 'full_name',
            email: 'email',
            phone_number: 'phone_number',
            about: 'event_details',
            lead_source: 'lead_source',
            project_type: 'event_type',
            project_location: 'event_location',
            project_budget: 'budget',
            project_date: 'project_date',
            guests: 'event_guests',
        },

        setsQuestions: {
            sets: 'sets',
            basic: 'basic',
            project_details: 'project_details',
            company_type: 'company_type',
            project_type: 'project_type',
            project_date: 'project_date',
            project_location: 'project_location',
            project_budget: 'project_budget',
            project_guest_count: 'project_guest_count',

        },

        // IMPORTANT: values lower case only.
        doodles: {
            winter: 'winter',
            pride: 'pride',
            july4th: '4th-july',
            usaFlag: 'usa-flag',
            thanksgiving: 'thanksgiving',
            blackLivesMatter: 'black-lives-matter',
            prideBlm: 'pride-blm'
        },

        proposalSelectionType: {
            noSelect: 'no_select',
            selectOne: 'select_one',
            selectOneRequired: 'select_one_required',
            selectMultiple: 'select_multiple',
            selectMultipleRequired: 'select_multiple_required'
        },

        calendarTypes: {
            payments: 'payments',
            tentativeProjects: 'tentativeProjects',
            team: 'team'
        },

        contactFormMembers: {
            recipient: { key: 'recipient', seniorityScore: 0, translationEntry: 'CONTACT_FORM.MEMBERS.ROLES._LEADS_RECIPIENT_', subtextTranslationEntry: 'CONTACT_FORM.MEMBERS.ROLES._LEADS_RECIPIENT_SUBTEXT_' },
            workspace_member: { key: 'workspace_member', seniorityScore: 1, translationEntry: 'CONTACT_FORM.MEMBERS.ROLES._WORKSPACE_PARTICIPANT_', subtextTranslationEntry: 'CONTACT_FORM.MEMBERS.ROLES._WORKSPACE_PARTICIPANT_SUBTEXT_' },
            owner: { icon: 'https://res.cloudinary.com/honeybook/image/upload/v1565595775/app/ui/crown.svg', key: 'owner', seniorityScore: 2, translationEntry: 'CONTACT_FORM.MEMBERS.ROLES._OWNER_', subtextTranslationEntry: 'CONTACT_FORM.MEMBERS.ROLES._OWNER_SUBTEXT_' },
        },

        pipelineActionsPopoverTypes: {
            stage: 'stage',
            archive: 'archive'
        },

        onboardingSteps: {
            account: 'account',
            branding: 'branding',
            services: 'services',
            clientSide: 'client_side',
            subscribe: 'subscribe',
            templates: 'templates',
            createProject: 'create_project',
            contactForm: 'contact_form',
            bankInfo: 'bank_info',
            accountSetup: 'account_setup',
            templateGallery: 'template_gallery',
            sendFlowTest: 'send_flow_test',
            clients: 'clients',
            servicesHub: 'services_hub'
        },

        calendarPopoverTypes: {
            google_event: 'google_event',
            nylas_event: 'nylas_event',
            hb_meeting: 'hb_meeting',
            lead_project: 'lead_project',
            booked_project: 'booked_project',
            payment: 'payment',
            day_click: 'day_click',
            event_limit: 'event_limit',
            team_project: 'team_project',
            team_meeting: 'team_meeting'
        },

        leadsInsightsStates: {
            locked: 'locked',
            collecting: 'collecting',
            results: 'results'
        },

        sessionType: {
            phoneCall: 'phone_call',
            inPerson: 'in_person',
            videoCall: 'video_call'
        },

        sessionWindowType: {
            indefinitely: 'indefinitely',
            fixedDateRange: 'fixed_date_range',
            rollingWindow: 'rolling_window'
        },

        giftCardStates: {
            promotionPurchased: 'promotion_purchased',
            voucherRedeemed: 'voucher_redeemed'
        },

        flowStates: {
            completed: 'flow_completed',
            submitted: 'flow_submitted',
            viewed: 'flow_viewed',
            deleted: 'flow_deleted',
            published: 'flow_published',
            publishedWithoutSharing: 'flow_published_without_sharing',
            draft: 'flow_draft_created'
        },

        flowPaymentActivities: {
            reminderSent: 'reminder_sent',
            markedAsPaid: 'mark_as_paid',
            markedAsUnpaid: 'undo_mark_as_paid',
            markedAsRefund: 'mark_as_refund',
            refund: 'refund',
            paid: 'paid',
            pending: 'pending',
            paymentRequest: 'payment_request',
        },

        flowSignatureActivityTypes: {
            success: 'flow_signature_success',
            collected: 'flow_signature_collected',
            countersignReady: 'flow_countersign_ready',
            completed: 'flow_contract_complete'
        },

        flowServiceSelectionTypes: {
            serviceSelected: 'flow_service_selected',
            automaticFlowCreatedBySelectedService: 'automatic_flow_created_by_selected_service',
            serviceSelectionChanged: 'flow_service_selection_changes',
        },

        paymentGateways: {
            Stripe: 'stripe'
        },

        flowActionStatuses: {
            pending: 'pending',
            completed: 'completed',
            inProgress: 'in_progress'
        },

        additionalVerificationDocuments: {
            photo_id_front: { key: 'photo_id_front', selected: false, label: 'Photo ID front', description: 'ID Front', state: ['CA', 'US'] },
            photo_id_back: { key: 'photo_id_back', selected: false, label: 'Photo ID back', description: 'ID Back', state: ['CA', 'US'] },
            holding_photo: { key: 'holding_photo', selected: false, label: 'Identification', description: 'Photo holding ID next to face', state: ['CA', 'US'] },
            voided_check: { key: 'voided_check', selected: false, label: 'Copy of voided check or bank statement', description: 'Account & Routing number', state: ['CA', 'US'] },
            utility_bill: { key: 'utility_bill', selected: false, label: 'Utility bill', description: 'Ex: Garbage/Water bill', state: ['CA', 'US'] },
            ss4: { key: 'ss4', selected: false, label: 'Copy of SS-4', description: 'SS-4 with EIN number', state: ['US']},
            bank_ownership_verification: { key: 'bank_ownership_verification', selected: false, label: 'Bank ownership verification', description: 'Bank statement or voided check that displays the last 4 digits of the bank account number', state: ['CA', 'US'] },
            company_verification_document: { key: 'company_verification_document', selected: false, label: 'Company verification document', description: 'Current and valid municipal, provincial, territorial, or federal government-issued document that contains your business name and address.', state: ['CA', 'US'] },
            proof_of_registration: { key: 'proof_of_registration', selected: false, label: 'Business directorship verification', description: 'Current and valid municipal, provincial, territorial, or federal government-issued document that contains your business directorship.', state: ['CA', 'US'] },
            guardian_photo_id: { key: 'guardian_photo_id', selected: false, label: 'Parent/ guardian photo ID', description: 'ID Front', state: ['CA', 'US'] },
            guardian_signed_letter: { key: 'guardian_signed_letter', selected: false, label: 'Parent/ guardian signed letter', description: 'Parent/ Guardian signed letter allowing you to use HoneyBook as a minor and authorizing the use of the parent/ guardian’s  photo ID, social security number and bank account in HoneyBook', state: ['CA', 'US'] },
            other: { key: 'other', selected: false, label: 'Other', description: '', state: ['CA', 'US'] },
            confirmation_notice: { key: 'confirmation_notice', selected: false, label: 'Copy of confirmation notice', description: 'Confirmation notice with business number', state: ['CA']},
        },

        additionalVerificationDocumentsByType:{
            account_verification: {label: 'HB Account Verification', documents: ['photo_id_front', 'photo_id_back', 'holding_photo' , 'voided_check', 'utility_bill' , 'ss4', 'confirmation_notice', 'other']},
            '2fa_reset' : {label: '2FA Reset', documents: ['photo_id_front', 'photo_id_back', 'holding_photo' , 'bank_ownership_verification', 'other']},
            bank_account_ownership: {label: 'Bank Account Ownership', documents: ['bank_ownership_verification', 'other']},
            parent_guardian_verification: {label: 'Parent/ Guardian Verification', documents: ['photo_id_front', 'photo_id_back', 'guardian_photo_id', 'guardian_signed_letter', 'other']},
            general: {label: 'General', documents: ['photo_id_front', 'photo_id_back', 'holding_photo' , 'voided_check', 'utility_bill' , 'ss4', 'confirmation_notice', 'bank_ownership_verification', 'guardian_photo_id', 'guardian_signed_letter', 'other']}
        },

        flowTemplateIds: {
            blank_invoice: '6396060c49d9ab002fe2c909',
            blank_scheduler: '6371ff42fff9a5002ccc1035'
        },
        accountSetupEvents: {
            fileUnreadable: {event: "file_setup.unreadable", buttonText: "File is corrupted"},
            invalidFileType: {event: "file_setup.invalid_file_type", buttonText: "Invalid file type"},
            langNotEnglish: {event: "file_setup.not_english", buttonText: "File content not in English"},
            blankInvoice: {event: "file_setup.blank_invoice", buttonText: "File is blank"}
        },

        ReasonTypes: [
            { key: 'fraud', label: 'Fraud investigation'},
            { key: 'learning', label: 'Learning'},
            { key: 'support', label: 'Support case'},
            { key: 'hotfix', label: 'Hotfix'},
            { key: 'other', label: 'Other'},
        ]
    };
};
